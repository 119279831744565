



















import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class CmxNewsList extends Vue {
    NewsList:any[]=[];
    bannerImg: string = '';
    isshow: boolean=false;
    swiperOption: object = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      scrollbar: {
        el: '.swiper-scrollbar'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      slidesPerView: 1.2,
      centeredSlides: true
    };
    showLang () {
      if (localStorage.locale === 'E') this.isshow = true;
    }
    loadNewsList () {
      var cond = {
        Key: 'MediaCoverage',
        Page: 1,
        PageSize: 3,
        SortOrder: 'DESC',
        SortName: 'CreateDate'
      };
      this.$Api.cms.getContentsByCatKeyEx(cond).then((result) => {
        this.NewsList = result.Data.slice(0, 3);
        result.Data.forEach(function (i) {
          var newDate = new Date(i.CreateDate.replace(/-/g, '/'));
          i.CreateDate = newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
        });
      });
    }
    showContent (val) {
      if (val.Url) {
        window.open(val.Url);
      } else {
        this.$router.push('/cms/content/' + val.Id);
      }
    }
    mounted () {
      this.loadNewsList();
      this.showLang();
    }
}
